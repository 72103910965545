@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import 'swiper/css/bundle';

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$royal: #3e4095;
$laranja: #f58634;
$royalEscuro: #1a1e41;

html {
  scroll-behavior: smooth;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: $royal;
}

body {
  font-family: "DM Sans", sans-serif !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
  color: #777 !important;
  text-rendering: optimizeLegibility;

  p {
    color: #777;
    font-family: "Inter", sans-serif;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 26px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
}

#header {
  background: rgba(34, 40, 45, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  transition: 0.3s ease-out;
  transition-property: height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    #btn-menu {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in;
      z-index: 999;
      img{
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
      }

      i {
        font-size: 16px;
        color: white;
      }
      @media (min-width: 1200px) {
        display: none;
      }
    }

    .link-img {
      width: 200px;
      height: auto;
      z-index: 9999;

      img {
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
      }

      @media (max-width: 768px) {
        width: 150px;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 30px;
      list-style: none;
      transition: all 0.5s ease;
      

      li {
        a {
          color: white;
          text-decoration: none;
          font-weight: 500;
          font-size: 16px;
          transition: 0.3s ease;
          &:hover {
            color: $laranja;
          }
        }
      }
      li.link-telefone {
        a {
          font-size: 20px;
          span {
            font-size: 16px;
            color: $laranja;
          }
        }
      }

      @media (max-width: 1200px) {
        flex-direction: column;
        position: fixed;
        right: -100%;
        background-color: $royal;
        z-index: 99;
        width: 100vw;
        justify-content: center;
        align-items: center;
        height: 100vh;
        top: 0;
        background-image: linear-gradient(
          0deg,
          rgba(40, 40, 40, 0.7),
          rgba(40, 40, 40, 0.7)
        ),
        url("/imgs/hero.jpg");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
      }
    }

    ul.active {
      right: 0;
    }

    @media (max-width: 768px) {
      height: 70px;
    }
  }
}

#hero {
  background-image: linear-gradient(
      0deg,
      rgba(40, 40, 40, 0.7),
      rgba(40, 40, 40, 0.7)
    ),
    url("/imgs/hero.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  .hero-info {
    height: 70vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 14px;

    p.hero-chamada-title {
      color: $laranja;
      display: flex;
      font-size: 14px;
      align-items: center;
      background-color: rgba(245, 132, 52, 0.1);
      padding: 5px 20px;
      border-radius: 100px;
    }

    p.hero-decription {
      color: white;
    }

    h1 {
      color: white;
      font-weight: bold;
      span {
        color: $laranja;
      }
    }

    .hero-button {
      button {
        border: 0;
        height: 45px;
        padding: 0 24px;
        color: white;
        font-weight: 600;
        border-radius: 4px;
        background-color: $laranja;
        transition: all 0.5s ease;

        &:hover {
          background-color: white;
          color: $laranja;
        }
      }
    }

    .card-infos {
      width: 488px;
      height: 233px;
      position: absolute;
      background-color: $royal;
      border-left: 4px solid $royalEscuro;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      bottom: 0;
      right: 0;
      border-radius: 4px;
      padding: 30px;
      @media(max-width:992px){
        display: none;
      }

      h5 {
        color: white;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
      }

      p {
        color: white;
        font-size: 14px;
      }

      a {
        display: flex;
        align-items: center;
        column-gap: 10px;
        text-decoration: none;
        float: right;
        color: white;

        i {
          background-color: white;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          transition: all 0.5s ease;
          cursor: pointer;
          color: $royal;
          font-size: 16px;

          &:hover {
            background-color: $laranja;
            color: white;
          }
        }
      }
    }
  }
}

#services {
  padding: 100px 0;
  // background-image: url("/imgs/background-white.png");
  background-color: #f9f9f9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 425px) {
    padding-top: 180px;
  }

  .header-services {
    margin-bottom: 50px;
    h2 {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 38px;
      color: $royalEscuro;
      padding-left: 20px;
      padding-top: 20px;
      z-index: 1;
      span {
        color: $laranja;
        font-weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        background-image: url("/imgs/Group.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0;
        z-index: -1;
      }
    }
  }

  .box-servicos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    gap: 20px;
    height: 350px;
    background-color: #ffffff;
    box-shadow: 0px 0px 60px rgba(188, 188, 188, 0.3);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s ease;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: $royalEscuro;
    }
    .services-description {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $royalEscuro;
    }

    a.service-button {
      text-decoration: none;
      cursor: pointer;

      button {
        display: none;
        border: 0;
        background-color: white;
        border: 1px solid $royal;
        height: 45px;
        padding: 0 50px;
        color: $royal;
        font-weight: 600;
        border-radius: 4px;
        transition: all 0.5s ease;
      }
    }

    &:hover {
      background-color: $royal;

      span.number {
        color: white;
        -webkit-text-stroke-width: 0;
      }

      h3,
      p {
        color: white;
      }

      a.service-button {
        button {
          display: block;
        }
      }
    }

    @media (max-width: 425px) {
      height: auto;
      padding: 50px 30px;
    }
  }
}

#sobre-nos {
  padding: 100px 0;
  .box-img {
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
    }
  }
  .box-text {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 38px;
      color: $royalEscuro;
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;
      padding-top: 20px;
      z-index: 1;
      span {
        color: $laranja;
        font-weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        background-image: url("/imgs/Group.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 0px;
        z-index: -1;
        top: 0;
      }
    }
    a {
      button {
        border: 0;
        height: 45px;
        padding: 0 24px;
        color: white;
        font-weight: 600;
        border-radius: 4px;
        background-color: $laranja;
        border: 1px solid $laranja;
        transition: 0.5s ease;

        &:hover {
          background-color: transparent;
          border: 1px solid $laranja;
          color: $laranja;
        }
      }
    }
  }
}

#diferenciais {
  padding: 100px 0;
  background-color: #f9f9f9;
  .box-img{
    width: 100%;
    height: auto;
    img{
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
    }
  }

  .header-diferenciais {
    margin-bottom: 50px;
    h2 {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 38px;
      color: $royalEscuro;
      padding-left: 20px;
      padding-top: 20px;
      z-index: 1;
      span {
        color: $laranja;
        font-weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        background-image: url("/imgs/Group.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0;
        z-index: -1;
      }
    }
  }
  .header-text {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: $royalEscuro;
      margin-bottom: 30px;
      span {
        color: $laranja;
        font-weight: 800;
      }
    }
  }
  .box-text {
    .etapa {
      margin-bottom: 20px;
      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: $royalEscuro;
        position: relative;
        padding-left: 60px;
        display: flex;
        align-items: center;
        &::before {
          content: "✓";
          color: #072125;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          width: 44px;
          height: 44px;
          border-radius: 12px;
          background-color: #dcf2ed;
          left: 0;
          top: 0;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-left: 60px;
      }
    }
  }
}

#frase-empresa {
  height: 600px;
  background-image: url("/imgs/imagem-frase.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  .box-frase {
    background: #ffffff;
    border-radius: 12px;
    height: 435px;
    padding: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    h3 {
      color: $royalEscuro;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
    }
    .box-aspas {
      display: block;
      width: 41px;
      height: 33px;
      img {
        width: 41px;
        height: 33px;
        object-fit: cover;
      }
    }
    @media(max-width: 390px){
      padding: 30px;
      h3{
        font-size: 26px;
        line-height: unset;
      }
    }
  }
}

#clientes {
  padding: 100px 0;
  height: 500px;
  display: flex;
  align-items: center;
  .header-clientes {
    margin-bottom: 100px;
    h2 {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 38px;
      color: $royalEscuro;
      padding-left: 20px;
      padding-top: 20px;
      z-index: 1;
      span {
        color: $laranja;
        font-weight: 800;
      }
      &::before {
        content: "";
        position: absolute;
        background-image: url("/imgs/Group.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0;
        z-index: -1;
      }
    }
  }

  .mySwiper {
    .swiper-slide {
      .box-img {
        width: 150px;
        height: 150px;
        cursor: pointer;
        margin: 0 auto;
        img {
          width: 100%;
          height: auto;
          aspect-ratio: 3/2;
          object-fit: contain;
          mix-blend-mode: color-burn;
          filter: grayscale(100%);
        }
      }
    }

    .swiper-pagination {
      span {
        transition: all 0.5s ease;
      }

      span.swiper-pagination-bullet-active {
        width: 30px;
        border-radius: 100px;
        background-color: $royal;
      }
    }
  }
}

#footer {
  background-color: #f9f9f9;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  .box-img {
    width: 200px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 30px;
    img {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
    }
  }
  .copy {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .social {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      span {
        a {
          text-decoration: none;
          i {
            color: $royal;
            font-size: 16px;
            background-color: #c9cce9;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: 0.5s ease;
          }
        }
        &:hover {
          a {
            i {
              color: $royal;
            }
          }
        }
      }
    }

    small,a {
      color: $royal;
      text-decoration: none;
    }

    span {
      font-weight: 400;
      color: $royal;
      font-size: 12px;
      font-family: "Inter", sans-serif;

      a {
        text-decoration: none;
        font-weight: bold;
        color: unset;
      }
    }
  }
}
